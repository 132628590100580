//DESKTOP | LAPTOP | TABLET
import React from "react";
import NavbarLarge from "./navbar/navbar-large";
import Hero from "./hero/hero-section";

const HeaderLarge = () => {
  return (
    <div className="relative overflow-hidden">
      <NavbarLarge />
      <Hero />
    </div>
  );
};

export default HeaderLarge;
