const assetIcons = {
  arrowRight: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/arrow-right.svg",
  check: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/check.svg",
  chevDown: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/chev-down.svg",
  clock: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/clock.svg",
  email: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/email.svg",
  facebook: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/facebook.svg",
  instagram: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/instagram.svg",
  linkedin: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/linkedin.svg",
  play: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/play.svg",
  twitter: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/twitter.svg",
  users: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/users.svg",
  video: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/video.svg",
  close: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/close.svg",
  menuBar: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/menu.svg",
  chevLeft: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/chev-left.svg",
  chevRight: "https://d1uxhsybh3036x.cloudfront.net/saas/svgs/chev-right.svg",
  // Add more as needed
};

const assetImages = {
  heroImage: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/large-hero.png",
  herosmallImage:
    "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/small-hero.png",
  discover: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/discover.png",
  tailored: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/tailored.png",
  noLimits: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/no-limits.png",
  free1: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/free1.png",
  free2: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/free2.png",
  free3: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/free3.png",
  video: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/video.png",
  yogaBall: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/yoga-ball.png",
  yogaMat: "https://d1uxhsybh3036x.cloudfront.net/saas/pngs/yoga-mat.png",
  // Add more as needed
};

const assetVideos = {
  heroVideo: "https://d1uxhsybh3036x.cloudfront.net/saas/vids/hero-video-2.mp4",
  // Add more as needed
};

export { assetIcons, assetImages, assetVideos };
