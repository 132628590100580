import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons, assetVideos } from "../../constants/assets";
import useWindowSize from "../../hooks/use-window-size";

const VideoDemo = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const paddingTopBottom = isMobile
    ? spacings.mobileTopBottomPagePadding
    : spacings.topBottomPagePadding;
  const paddingLeftRight = isMobile
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  const containerVariants = {
    hidden: { opacity: 0, translateY: 20 },
    visible: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, translateY: -30, scale: 0.95 },
    visible: {
      opacity: 1,
      translateY: 0,
      scale: 1,
      transition: { duration: 1, delay: 0.5, ease: "easeOut" },
    },
  };

  const videoVariants = {
    hidden: { opacity: 0, scale: 0.5, rotate: 2 },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: { duration: 1, delay: 0.5, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      className="flex flex-col gap-6 relative overflow-hidden items-center "
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.demoBg,
      }}
      id="demo"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <motion.div variants={titleVariants}>
        <h3
          className="font-bold text-center"
          style={{
            fontSize: fontSizes.heading3,
            color: colors.menuText,
          }}
        >
          {t("videoTitle")}
        </h3>
      </motion.div>

      <motion.div variants={titleVariants}>
        <p
          className="text-center"
          style={{
            fontSize: fontSizes.body,
            color: colors.menuText,
          }}
        >
          {t("videoDescription")}
        </p>
      </motion.div>

      <motion.div
        className="relative z-10 mx-8 sm:mx-16 md:mx-12 min-[912px]:mx-24 sm:mb-0 mb-3"
        variants={videoVariants}
      >
        <div className="relative rounded-3xl overflow-hidden z-10 bg-white">
          {/* Window Header */}
          <div className="px-4 py-2 flex justify-between items-center">
            {/* Window Controls */}
            <div className="flex gap-2 ">
              <div
                className="w-2 h-2 rounded-full"
                style={{
                  backgroundColor: colors.redScreen,
                }}
              ></div>
              <div
                className="w-2 h-2 rounded-full"
                style={{
                  backgroundColor: colors.yellowScreen,
                }}
              ></div>
              <div
                className="w-2 h-2 rounded-full"
                style={{
                  backgroundColor: colors.greenScreen,
                }}
              ></div>
            </div>

            {/* Window Title */}
            <div
              className="flex-1 text-center text-sm md:p-2 p-1 rounded-lg md:mx-10 mx-2"
              style={{
                backgroundColor: colors.screenBox,
                color: colors.screenText,
                fontSize: fontSizes.info,
              }}
            >
              joinourevolution.com
            </div>

            {/* Navigation Arrows */}
            <div className="flex md:gap-2 gap-1 flex-shrink-0">
              <img
                src={assetIcons.chevLeft}
                alt=""
                className="flex-shrink-0 md:w-6 w-5"
              />
              <img
                src={assetIcons.chevRight}
                alt=""
                className="flex-shrink-0 md:w-6 w-5"
              />
            </div>
          </div>

          {/* Window Content (Video) */}
          <div className="">
            <video
              src={assetVideos.heroVideo}
              width={1000}
              className="mx-auto "
              autoPlay
              controls
              loop
            ></video>
          </div>
        </div>

        {/* Responsive Circle 1 */}
        <div
          className="absolute rounded-full "
          style={{
            backgroundColor: colors.videoRound,
            width: "20vw", // Adjust width based on viewport width
            height: "20vw", // Adjust height based on viewport width
            top: "30%",
            right: "-14%",
            zIndex: -1,
          }}
        ></div>

        {/* Responsive Circle 2 */}
        <div
          className="absolute rounded-full"
          style={{
            backgroundColor: colors.logoGradStart,
            width: "16vw",
            height: "16vw",
            left: "-8%",
            bottom: "-10%",
            zIndex: -1,
          }}
        ></div>

        {/* Responsive Circle 3 */}
        <div
          className="absolute rounded-full"
          style={{
            backgroundColor: colors.yogaMatRound,
            width: "8vw",
            height: "8vw",
            left: "-13%",
            top: "10%",
            zIndex: -1,
          }}
        ></div>
      </motion.div>
    </motion.div>
  );
};

export default VideoDemo;
