const spacings = {
  mediumSpacer: 40,
  leftRightPagePaddingNav: 56,
  topBottomPagePaddingNav: 24,
  mobileLeftRightPagePaddingNav: 24,
  mobileTopBottomPagePaddingNav: 20,
  topPagePaddingStats: 30,
  leftRightPagePadding: 56,
  topBottomPagePadding: 48,
  topBottomPagePaddingHero: 90,
  mobileLeftRightPagePadding: 24,
  mobileTopBottomPagePadding: 28,
  tabletTopBottomPagePaddingHero: 80,
  navbarSpacing: 50,
  buttonYPadding: 16,
  buttonXPadding: 40,
  pricingCardY: 24,
  pricingCardX: 16,
  innerPrice: 20,
  faqPadding: 22,
  emailLeft: 50,
  emailY: 16,
  emailRight: 10,
  // Add more spacings as needed
};

export default spacings;
