import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useTheme } from "../../../providers/theme-provider";
import { fontSizes } from "../../../theme/font-sizes";
import spacings from "../../../theme/spacings";
import { assetIcons } from "../../../constants/assets";
import { Link } from "react-router-dom";
import { socialRedirectUrls, urls } from "../../../constants/urls";

function CtaButton({ label, onClick = () => {}, style = {} }) {
  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.button
      className="rounded-[30px] font-bold flex items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: colors.ctaBg,
        color: colors.buttonText,
        fontSize: fontSizes.buttonText,
        padding: `${spacings.buttonYPadding}px ${spacings.buttonXPadding}px`,
        cursor: "pointer",
        ...style,
      }}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
    >
      <Link to={urls.dijinx}>{label}</Link>

      <motion.img
        src={assetIcons.arrowRight}
        alt="arrowRight"
        className="w-6 h-6 ml-2" // Adjust size as needed
        animate={{ x: isHovered ? 5 : 0 }}
        transition={{ duration: 0.3 }}
      />
    </motion.button>
  );
}

CtaButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default CtaButton;
