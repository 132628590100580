import React, { useState } from "react";
import spacings from "../../theme/spacings";
import { useTheme } from "../../providers/theme-provider";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons } from "../../constants/assets";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hooks/use-window-size";
import { motion } from "framer-motion"; // Importing framer motion
import { Link } from "react-router-dom";
import { urls } from "../../constants/urls";

const PricingSection = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const paddingTopBottom = isMobile
    ? spacings.mobileTopBottomPagePadding
    : spacings.topBottomPagePadding;
  const paddingLeftRight = isMobile
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDetails = () => setIsExpanded(!isExpanded);

  // Animation settings
  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <div
      className="flex flex-col gap-8 overflow-x-hidden"
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.background,
      }}
      id="pricing"
    >
      <h3
        style={{
          fontSize: fontSizes.heading3,
          color: colors.menuText,
        }}
        className="text-center font-bold"
      >
        {t("pricingSection.title")}
      </h3>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 ">
        {/* Free Plan Card */}
        <motion.div
          className={`flex flex-col items-center justify-between gap-4 rounded-xl ${
            isExpanded ? "min-h-[648px]" : "md:min-h-[648px]"
          }`}
          style={{
            backgroundColor: colors.background,
            padding: `${spacings.pricingCardY}px ${spacings.pricingCardX}px`,
            boxShadow: "0px 4px 9px 0px #0000000D",
          }}
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }} // Trigger animation on entering viewport
        >
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-3">
              <h3
                className="font-bold text-center"
                style={{
                  fontSize: fontSizes.priceTitle,
                  color: colors.menuText,
                }}
              >
                {t("pricingSection.plans.free.title")}
              </h3>

              <p
                style={{
                  color: colors.pricingText,
                  fontSize: fontSizes.body,
                }}
                className="text-center max-w-[230px]"
              >
                {t("pricingSection.plans.free.description")}
              </p>
            </div>

            <div className="flex gap-1 justify-center">
              <sup
                className="mt-3"
                style={{
                  fontSize: fontSizes.body,
                  color: colors.pricingText,
                }}
              >
                $
              </sup>
              <p
                className="font-bold"
                style={{
                  fontSize: fontSizes.price,
                  color: colors.menuText,
                }}
              >
                {t("pricingSection.plans.free.price")}
              </p>
            </div>
          </div>

          {/* Expandable Part - Hidden by default on mobile */}
          <div
            className={`flex-col gap-8 justify-between h-[380px] rounded-xl w-full ${
              isExpanded ? "flex" : "hidden md:flex"
            }`}
            style={{
              backgroundColor: colors.demoBg,
              padding: spacings.innerPrice,
            }}
          >
            <div className="flex flex-col gap-6">
              {t("pricingSection.plans.free.features", {
                returnObjects: true,
              }).map((feature, index) => (
                <div key={index} className="flex gap-4 items-center">
                  <img src={assetIcons.check} alt="" className="w-6" />
                  <p
                    style={{
                      fontSize: fontSizes.innerPrice,
                      color: colors.menuText,
                    }}
                  >
                    {feature}
                  </p>
                </div>
              ))}
            </div>
            <motion.button
              className="font-bold rounded-[20px] w-full mx-auto"
              style={{
                backgroundColor: colors.background,
                padding: `${spacings.buttonYPadding}px 0px`,
                color: colors.yogaRound,
                fontSize: fontSizes.buttonText,
              }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <Link to={urls.freePlan}>
                {t("pricingSection.plans.free.buttonText")}
              </Link>
            </motion.button>
          </div>

          {/* "More Details" Button - Only on mobile */}
          <motion.button
            onClick={toggleDetails}
            className="font-semibold rounded-xl w-full underline md:hidden block"
            style={{
              backgroundColor: colors.demoBg,
              padding: `${spacings.buttonYPadding}px 0px`,
              color: colors.primary,
              fontSize: fontSizes.buttonText,
            }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            {isExpanded
              ? t("pricingSection.lessDetails")
              : t("pricingSection.moreDetails")}
          </motion.button>
        </motion.div>

        {/* Pro Plan Card */}
        <motion.div
          className="flex flex-col items-center justify-between gap-4 rounded-xl relative min-h-[688px] overflow-hidden"
          style={{
            backgroundColor: colors.proPrice,
            padding: `${spacings.pricingCardY}px ${spacings.pricingCardX}px`,
            boxShadow: "0px 4px 9px 0px #0000000D",
          }}
          whileInView={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <div className="flex flex-col gap-10 relative z-20">
            <div className="flex flex-col gap-3">
              <h3
                className="font-bold text-center"
                style={{
                  fontSize: fontSizes.priceTitle,
                  color: colors.background,
                }}
              >
                {t("pricingSection.plans.pro.title")}
              </h3>

              <p
                style={{
                  color: colors.background,
                  fontSize: fontSizes.body,
                }}
                className="text-center max-w-[230px]"
              >
                {t("pricingSection.plans.pro.description")}
              </p>
            </div>

            <div className="flex gap-1 justify-center ">
              <sup
                className="mt-3"
                style={{
                  fontSize: fontSizes.body,
                  color: colors.background,
                }}
              >
                $
              </sup>
              <p
                className="font-bold"
                style={{
                  fontSize: fontSizes.price,
                  color: colors.background,
                }}
              >
                {t("pricingSection.plans.pro.price")}
              </p>
            </div>
          </div>

          <div
            className="flex flex-col gap-8 justify-between min-h-[380px] rounded-xl w-full relative z-20"
            style={{
              backgroundColor: colors.demoBg,
              padding: spacings.innerPrice,
            }}
          >
            <div className="flex flex-col gap-6">
              {t("pricingSection.plans.pro.features", {
                returnObjects: true,
              }).map((feature, index) => (
                <div key={index} className="flex gap-4 items-center">
                  <img src={assetIcons.check} alt="" className="w-6" />
                  <p
                    style={{
                      fontSize: fontSizes.innerPrice,
                      color: colors.menuText,
                    }}
                  >
                    {feature}
                  </p>
                </div>
              ))}
            </div>

            <motion.button
              className="font-bold rounded-[20px] mx-auto w-full"
              style={{
                backgroundColor: colors.yogaRound,
                padding: `${spacings.buttonYPadding}px 0px`,
                color: colors.background,
                fontSize: fontSizes.buttonText,
              }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <Link to={urls.proPlan}>
                {t("pricingSection.plans.pro.buttonText")}
              </Link>
            </motion.button>
          </div>

          <div
            className="h-[650px] w-[650px] rounded-full overflow-hidden absolute top-36 -z-0"
            style={{
              backgroundColor: colors.priceCircle,
            }}
          ></div>
        </motion.div>

        {/* Business Plan Card */}
        <motion.div
          className={`flex flex-col items-center justify-between gap-4 rounded-xl ${
            isExpanded ? "min-h-[648px]" : "md:min-h-[648px]"
          }`}
          style={{
            backgroundColor: colors.background,
            padding: `${spacings.pricingCardY}px ${spacings.pricingCardX}px`,
            boxShadow: "0px 4px 9px 0px #0000000D",
          }}
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          <div className="flex flex-col gap-10">
            {" "}
            <div className="flex flex-col gap-3">
              {" "}
              <h3
                className="font-bold text-center"
                style={{
                  fontSize: fontSizes.priceTitle,
                  color: colors.menuText,
                }}
              >
                {" "}
                {t("pricingSection.plans.business.title")}{" "}
              </h3>
              <p
                style={{
                  color: colors.pricingText,
                  fontSize: fontSizes.body,
                }}
                className="text-center max-w-[230px]"
              >
                {t("pricingSection.plans.business.description")}
              </p>
            </div>
            <div className="flex gap-1 justify-center">
              <sup
                className="mt-3"
                style={{
                  fontSize: fontSizes.body,
                  color: colors.pricingText,
                }}
              >
                $
              </sup>
              <p
                className="font-bold"
                style={{
                  fontSize: fontSizes.price,
                  color: colors.menuText,
                }}
              >
                {t("pricingSection.plans.business.price")}
              </p>
            </div>
          </div>

          <div
            className={`flex-col gap-8 justify-between h-[380px] rounded-xl w-full ${
              isExpanded ? "flex" : "hidden md:flex"
            }`}
            style={{
              backgroundColor: colors.demoBg,
              padding: spacings.innerPrice,
            }}
          >
            <div className="flex flex-col gap-6">
              {t("pricingSection.plans.business.features", {
                returnObjects: true,
              }).map((feature, index) => (
                <div key={index} className="flex gap-4 items-center">
                  <img src={assetIcons.check} alt="" className="w-6" />
                  <p
                    style={{
                      fontSize: fontSizes.innerPrice,
                      color: colors.menuText,
                    }}
                  >
                    {feature}
                  </p>
                </div>
              ))}
            </div>

            <motion.button
              className="font-bold rounded-[20px] w-full mx-auto"
              style={{
                backgroundColor: colors.background,
                padding: `${spacings.buttonYPadding}px 0px`,
                color: colors.yogaRound,
                fontSize: fontSizes.buttonText,
              }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <Link to={urls.businessPlan}>
                {t("pricingSection.plans.business.buttonText")}
              </Link>
            </motion.button>
          </div>

          <motion.button
            onClick={toggleDetails}
            className="font-semibold rounded-xl w-full underline md:hidden block"
            style={{
              backgroundColor: colors.demoBg,
              padding: `${spacings.buttonYPadding}px 0px`,
              color: colors.primary,
              fontSize: fontSizes.buttonText,
            }}
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            {isExpanded
              ? t("pricingSection.lessDetails")
              : t("pricingSection.moreDetails")}
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default PricingSection;
