import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "../../../providers/theme-provider";
import spacings from "../../../theme/spacings";
import { useTranslation } from "react-i18next";
import { fontSizes } from "../../../theme/font-sizes";
import CtaButton from "../../buttons/cta-button/cta-button";
import SecondaryButton from "../../buttons/secondary-button/secondary-button";
import { assetImages } from "../../../constants/assets";
import useWindowSize from "../../../hooks/use-window-size";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";

const Hero = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const handleScroll = useSmoothScroll();

  const { width } = useWindowSize();
  const isTablet = width < 1024;
  const isMobile = width < 768;

  const paddingTopBottom = isTablet
    ? spacings.tabletTopBottomPagePaddingHero
    : spacings.topBottomPagePaddingHero;
  const paddingLeftRight = isTablet
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  // const height = isMobile ? "calc(100vh - 84px)" : "";

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      className="flex flex-col lg:justify-start sm:justify-center justify-center lg:h-auto "
      style={{
        backgroundColor: colors.backgoround,
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        // height: height,
      }}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="flex flex-col sm:gap-6 gap-8 lg:max-w-[50%] sm:leading-tight leading-snug lg:mt-10 sm:mt-44 mt-0"
        variants={itemVariants}
      >
        <div className="">
          <motion.h3
            className="font-bold relative"
            style={{
              fontSize: fontSizes.heading1,
            }}
            variants={itemVariants}
          >
            {t("heroMainText")}
            <motion.span
              className="absolute -bottom-3 left-0 w-full h-[7px] rounded-2xl"
              style={{
                backgroundColor: colors.yogaRound,
              }}
              initial={{ width: 0 }}
              animate={{ width: "40%" }}
              transition={{ duration: 1.5, ease: "easeOut", delay: 0.4 }}
            />
          </motion.h3>
        </div>
        <div>
          <motion.p
            className="font-normal"
            style={{
              fontSize: fontSizes.heading2,
            }}
            variants={itemVariants}
          >
            {t("heroSubText")}
          </motion.p>
        </div>
        <motion.div
          className="flex md:flex-row flex-col gap-6 md:items-center items-start"
          variants={itemVariants}
        >
          <CtaButton label={t("heroCta")} />
          <SecondaryButton
            label={t("viewDemo")}
            style={{
              backgroundColor: "transparent",
              color: colors.demoButtonText,
            }}
            onClick={() => handleScroll("demo")}
          />
        </motion.div>
      </motion.div>

      <motion.div
        className="absolute top-0 left-0 right-0 w-full -z-10 lg:hidden block"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
      >
        <div className="relative ">
          <img
            src={assetImages.herosmallImage}
            alt=""
            className=" w-full h-full"
            style={{
              position: "relative",
            }}
          />
        </div>
      </motion.div>

      <motion.div
        className="absolute right-0 -top-10 lg:block hidden max-w-[50%]"
        initial={{ x: 50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.6, ease: "easeOut" }}
      >
        <div className="">
          <img
            src={assetImages.heroImage}
            alt=""
            className="max-w-[400px] md:max-w-[400px] lg:max-w-[600px] xl:max-w-[640px] "
          />
        </div>

        <motion.div
          className="absolute z-50 left-0 top-40 xl:w-20 xl:h-20 w-16 h-16 rounded-full  items-center justify-center text-white p-3 "
          style={{
            backgroundColor: colors.videoRound,
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.8, ease: "backOut" }}
        >
          <img src={assetImages.video} alt="" />
        </motion.div>
        <motion.div
          className="absolute z-50 left-4 bottom-24 xl:w-20 xl:h-20 w-16 h-16 rounded-full md:flex hidden items-center justify-center text-white p-3"
          style={{
            backgroundColor: colors.yogaRound,
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.9, ease: "backOut" }}
        >
          <img src={assetImages.yogaBall} alt="" />
        </motion.div>
        <motion.div
          className="absolute z-50 xl:right-28 right-12 bottom-40 xl:w-20 xl:h-20 w-16 h-16 rounded-full md:flex hidden items-center justify-center text-white p-3"
          style={{
            backgroundColor: colors.yogaMatRound,
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 1, ease: "backOut" }}
        >
          <img src={assetImages.yogaMat} alt="" />
        </motion.div>
        <motion.div
          className="absolute z-50 left-[63%] -bottom-2 w-16 h-16 rounded-full md:flex hidden items-center justify-center text-white p-3"
          style={{
            backgroundColor: colors.lightBlueRound,
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 1, ease: "backOut" }}
        ></motion.div>
        <motion.div
          className="absolute z-50 -right-6 top-[30%] w-16 h-16 rounded-full md:flex hidden items-center justify-center text-white p-3"
          style={{
            backgroundColor: colors.yogaRound,
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 1, ease: "backOut" }}
        ></motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Hero;
