import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useTheme } from "../../../providers/theme-provider";
import { fontSizes } from "../../../theme/font-sizes";
import { assetIcons } from "../../../constants/assets";

function SecondaryButton({ label, onClick = () => {}, style = {} }) {
  const { colors } = useTheme();

  return (
    <motion.button
      className="rounded-[30px] font-bold flex items-center px-3"
      onClick={onClick}
      style={{
        backgroundColor: colors.ctaBg,
        color: colors.buttonText,
        fontSize: fontSizes.buttonText,
        cursor: "pointer",
        ...style,
      }}
      whileHover={{ scale: 1.05 }} // Hover effect
      transition={{ duration: 0.3 }}
    >
      <img src={assetIcons.play} alt="videoIcon" className="mr-2" />
      {label}
    </motion.button>
  );
}

SecondaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default SecondaryButton;
