import React from "react";
import { useTheme } from "../../providers/theme-provider";
import spacings from "../../theme/spacings";
import { useTranslation } from "react-i18next";
import { fontSizes } from "../../theme/font-sizes";
import { assetIcons, assetImages } from "../../constants/assets";
import useWindowSize from "../../hooks/use-window-size";
import { motion } from "framer-motion";

const FeatureSection = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const paddingTopBottom = isMobile
    ? spacings.mobileTopBottomPagePadding
    : spacings.topBottomPagePadding;
  const paddingLeftRight = isMobile
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  const featureVariants = (direction) => ({
    hidden: { x: direction === "left" ? -50 : 50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  });

  return (
    <div
      className="flex flex-col md:gap-12 gap-8 overflow-x-hidden"
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
        backgroundColor: colors.background,
      }}
      id="features"
    >
      <motion.div
        className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-items-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.div
          className="flex flex-col gap-6"
          variants={featureVariants("left")}
        >
          <div className="flex flex-col gap-3">
            <h4
              className="uppercase font-semibold"
              style={{
                fontSize: fontSizes.heading2,
                color: colors.featureText,
              }}
            >
              {t("featuresSection.discover.title")}
            </h4>

            <h3
              className="font-bold leading-tight"
              style={{
                fontSize: fontSizes.heading3,
                color: colors.menuText,
              }}
            >
              {t("featuresSection.discover.subtitle")}
            </h3>
          </div>

          <p
            className="md:max-w-xl max-w-full"
            style={{
              fontSize: fontSizes.body,
              color: colors.menuText,
            }}
          >
            {t("featuresSection.discover.description")}
          </p>
        </motion.div>
        <motion.div
          className="relative"
          whileInView={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <div
            className="absolute md:-bottom-8 -bottom-6 lg:-left-12 md:-left-6 -left-4 z[0] w-[96%] h-[96%] rounded-full "
            style={{
              borderRadius: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              backgroundColor: colors.purpleShape,
            }}
            aria-hidden="true"
          />

          <div
            className="relative max-w-2xl w-full rounded-3xl overflow-hidden z-10"
            style={{
              border: `5px solid ${colors.featureBorder}`,
            }}
          >
            <div className="bg-gray-100 px-4 py-2 flex items-center">
              <div className="flex gap-2 ">
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.redScreen,
                  }}
                ></div>
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.yellowScreen,
                  }}
                ></div>
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.greenScreen,
                  }}
                ></div>
              </div>

              {/* Window Title */}
              <div
                className="flex-1 text-center text-sm md:p-2 p-1 rounded-lg md:mx-10 mx-2"
                style={{
                  backgroundColor: colors.screenBox,
                  color: colors.screenText,
                  fontSize: fontSizes.info,
                }}
              >
                wr.app
              </div>

              {/* Navigation Arrows */}
              <div className="flex md:gap-2 gap-1 flex-shrink-0">
                <img
                  src={assetIcons.chevLeft}
                  alt=""
                  className="flex-shrink-0 md:w-6 w-5"
                />
                <img
                  src={assetIcons.chevRight}
                  alt=""
                  className="flex-shrink-0 md:w-6 w-5"
                />
              </div>
            </div>
            <div className="">
              <img src={assetImages.discover} alt="" className="" />
            </div>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-content-between place-items-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.div
          className="flex flex-col gap-6 md:order-2 order-1 "
          variants={featureVariants("right")}
        >
          <div className="flex flex-col gap-3">
            <h4
              className="uppercase font-semibold "
              style={{
                fontSize: fontSizes.heading2,
                color: colors.featureText,
              }}
            >
              {t("featuresSection.tailored.title")}
            </h4>

            <h3
              className="font-bold leading-tight md:max-w-xl max-w-full"
              style={{
                fontSize: fontSizes.heading3,
                color: colors.menuText,
              }}
            >
              {t("featuresSection.tailored.subtitle")}
            </h3>
          </div>

          <p
            className=""
            style={{
              fontSize: fontSizes.body,
              color: colors.menuText,
            }}
          >
            {t("featuresSection.tailored.description")}
          </p>
        </motion.div>

        <motion.div
          className="relative md:order-1 order-2"
          whileInView={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <div
            className="absolute md:-bottom-10 -bottom-6 lg:-right-12 md:-right-6 -right-4 z[0] w-[96%] h-[100%] rounded-full "
            style={{
              borderRadius: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              backgroundColor: colors.purpleShape,
            }}
            aria-hidden="true"
          />
          <div
            className="relative max-w-2xl w-full rounded-3xl overflow-hidden "
            style={{
              border: `5px solid ${colors.featureBorder}`,
            }}
          >
            {/* Window Header */}
            <div className="bg-gray-100 px-4 py-2 flex items-center">
              {/* Window Controls */}
              <div className="flex gap-2 ">
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.redScreen,
                  }}
                ></div>
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.yellowScreen,
                  }}
                ></div>
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.greenScreen,
                  }}
                ></div>
              </div>

              {/* Window Title */}
              <div
                className="flex-1 text-center text-sm md:p-2 p-1 rounded-lg md:mx-10 mx-2"
                style={{
                  backgroundColor: colors.screenBox,
                  color: colors.screenText,
                  fontSize: fontSizes.info,
                }}
              >
                wr.app
              </div>

              {/* Navigation Arrows */}
              <div className="flex md:gap-2 gap-1 flex-shrink-0">
                <img
                  src={assetIcons.chevLeft}
                  alt=""
                  className="flex-shrink-0 md:w-6 w-5"
                />
                <img
                  src={assetIcons.chevRight}
                  alt=""
                  className="flex-shrink-0 md:w-6 w-5"
                />
              </div>
            </div>
            <div className="">
              <img src={assetImages.tailored} alt="" className="" />
            </div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-content-between place-items-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.div
          className="flex flex-col gap-6 "
          variants={featureVariants("left")}
        >
          <div className="flex flex-col gap-3">
            <h4
              className="uppercase font-semibold"
              style={{
                fontSize: fontSizes.heading2,
                color: colors.featureText,
              }}
            >
              {t("featuresSection.noLimits.title")}
            </h4>

            <h3
              className="font-bold leading-tight"
              style={{
                fontSize: fontSizes.heading3,
                color: colors.menuText,
              }}
            >
              {t("featuresSection.noLimits.subtitle")}
            </h3>
          </div>

          <p
            className="md:max-w-xl max-w-full"
            style={{
              fontSize: fontSizes.body,
              color: colors.menuText,
            }}
          >
            {t("featuresSection.noLimits.description")}
          </p>
        </motion.div>
        <motion.div
          className="relative md:order-1 order-2"
          whileInView={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <div
            className="absolute md:-bottom-10 -bottom-6 lg:-right-12 md:-right-6 -right-4 z[0] w-[96%] h-[100%] rounded-full "
            style={{
              borderRadius: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              backgroundColor: colors.purpleShape,
            }}
            aria-hidden="true"
          />
          <div
            className="relative max-w-2xl w-full rounded-3xl overflow-hidden "
            style={{
              border: `5px solid ${colors.featureBorder}`,
            }}
          >
            {/* Window Header */}
            <div className="bg-gray-100 px-4 py-2 flex items-center">
              {/* Window Controls */}
              <div className="flex gap-2 ">
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.redScreen,
                  }}
                ></div>
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.yellowScreen,
                  }}
                ></div>
                <div
                  className="md:w-3 md:h-3 w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: colors.greenScreen,
                  }}
                ></div>
              </div>

              {/* Window Title */}
              <div
                className="flex-1 text-center text-sm md:p-2 p-1 rounded-lg md:mx-10 mx-2"
                style={{
                  backgroundColor: colors.screenBox,
                  color: colors.screenText,
                  fontSize: fontSizes.info,
                }}
              >
                wr.app
              </div>

              {/* Navigation Arrows */}
              <div className="flex md:gap-2 gap-1 flex-shrink-0">
                <img
                  src={assetIcons.chevLeft}
                  alt=""
                  className="flex-shrink-0 md:w-6 w-5"
                />
                <img
                  src={assetIcons.chevRight}
                  alt=""
                  className="flex-shrink-0 md:w-6 w-5"
                />
              </div>
            </div>
            <div className="">
              <img src={assetImages.noLimits} alt="" className="" />
            </div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        className="grid md:grid-cols-2 grid-cols-1 lg:gap-20 md:gap-12 gap-6 place-content-between place-items-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.div
          className="flex flex-col gap-6 md:order-2 order-1"
          variants={featureVariants("right")}
        >
          <div className="flex flex-col gap-3">
            <h3
              className="font-bold leading-tight"
              style={{
                fontSize: fontSizes.heading3,
                color: colors.menuText,
              }}
            >
              {t("featuresSection.freeYourself.subtitle")}
            </h3>
          </div>

          <p
            className="md:max-w-xl max-w-full"
            style={{
              fontSize: fontSizes.body,
              color: colors.menuText,
            }}
          >
            {t("featuresSection.freeYourself.description")}
          </p>
        </motion.div>

        <motion.div
          className="flex lg:gap-4 md:gap-2 gap-4 md:order-1 order-2 min-h-[70%]"
          whileInView={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          viewport={{ once: true }}
        >
          <img
            src={assetImages.free1}
            alt=""
            className="rounded-[20px] w-[31%] h-[69%] min-[540px]:mt-20 mt-16 object-cover "
          />
          <img
            src={assetImages.free2}
            alt=""
            className="rounded-[20px] w-[31%] h-[69%] object-cover "
          />
          <img
            src={assetImages.free3}
            alt=""
            className="rounded-[20px] w-[31%] h-[69%] mt-10 object-cover"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default FeatureSection;
