//MOBILE

import React from "react";
import NavbarSmall from "./navbar/navbar-small";
import Hero from "./hero/hero-section";
import useWindowSize from "../../hooks/use-window-size";

const HeaderSmall = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  // const height = isMobile ? "100vh" : "";
  return (
    <div
      className="relative overflow-hidden"
      style={
        {
          // height: height,
        }
      }
    >
      <NavbarSmall />
      <Hero />
    </div>
  );
};

export default HeaderSmall;
