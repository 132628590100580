//GLOBAL ENGLISH

const englishTranslations = {
  logoText: "Join Our Evolution",
  logoTextSmall: "JOE",
  navItems: {
    demo: "Demo",
    features: "Features",
    pricing: "Pricing",
    faq: "FAQ",
  },
  heroMainText: "Pilates Studio at your Fingertips",
  heroSubText:
    "Create custom Pilates instruction videos in minutes. Whether you're a trainer, fitness enthusiast, or studio owner, our platform empowers you to craft, share, and grow your practice. Start your evolution today!",
  heroCta: "Get Started",
  viewDemo: "View Demo",
  stats: {
    stat1: {
      title: "400+ Exercise Videos",
      description:
        "Access high quality videos from our extensive exercise library",
    },
    stat2: {
      title: "500+ Active Users",
      description: "Join our active users transforming their fitness",
    },
    stat3: {
      title: "10000+ Hours Played",
      description: "Hours of content already shaping healthier lives",
    },
  },
  videoTitle: "All the tools that you need",
  videoDescription:
    "Experience the power of personalized Pilates instruction lessons. Create, customize, present and share your own lectures in minutes",
  featuresSection: {
    discover: {
      title: "Discover",
      subtitle: "Explore our large video collection",
      description:
        "Discover hundreds of expertly crafted Pilates videos tailored to all skill levels. Whether you're a beginner or an advanced practitioner, find the perfect exercises to meet your needs.",
    },
    tailored: {
      title: "Tailored",
      subtitle: "Make it, just the way you like it",
      description:
        "Easily personalize your presentations with our intuitive tools. Mix and match exercises, adjust duration, and add your unique style to create sessions that fit your goals.",
    },
    noLimits: {
      title: "No Limits",
      subtitle: "Present on any device, anywhere",
      description:
        "Seamlessly share and showcase your presentations on any device, anytime. Whether you're teaching at home, in the studio, or on the go, your content is always accessible and ready to inspire.",
    },
    freeYourself: {
      subtitle: "Free Yourself to Focus on What Truly Matters",
      description:
        "We take the hassle out of content creation giving you more time to focus on your clients and your passion.",
    },
  },
  pricingSection: {
    title: "Affordable Pricing Plans",
    lessDetails: "Less Details",
    moreDetails: "More Details",
    plans: {
      free: {
        title: "Free",
        description: "Have a go and test your superpowers",
        price: "0",
        features: [
          "Unlimited Presentations",
          "3 Workouts",
          "5 Sections",
          "5 Exercises Per Section",
        ],
        buttonText: "Signup for free",
      },
      pro: {
        title: "Pro",
        description: "Experiment the power of infinite possibilities",
        price: "8",
        features: [
          "Unlimited Presentations",
          "Unlimited Workouts",
          "Unlimited Sections",
          "Unlimited Exercises",
          "Theme Personalisation",
        ],
        buttonText: "Go to Pro",
      },
      business: {
        title: "Business",
        description: "Unveil new superpowers and join the Enterprise League",
        price: "16",
        features: [
          "All the features of pro plan",
          "Custom Logo",
          "24/7 Priority Support",
        ],
        buttonText: "Signup for Business",
      },
    },
  },
  faq: {
    sectionTitle: "Frequently Asked Questions",
    items: [
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        title: "What is Lorem Ipsum?",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
    ],
  },
  ctaSection: {
    title: "Join our evolution today",
    description:
      "Join 500 active users and access 400+ expert-led Pilates videos—over 10,000 hours of content already shaping healthier lives. Start your journey with us today!",
    buttonLabel: "Get Started",
  },

  footer: {
    getStarted: "Get Started",
    watchDemo: "Watch The Demo",
    followUs: "Follow Us",
    terms: "Terms & Conditions",
    privacy: "Privacy Policy",
    feedback: "Suggestions & Feedback",
    helpDesk: "Help Desk",
    subscribe: "Subscribe to our Newsletter",
    emailPlaceholder: "example@dijinx.com",
    copyright: "© 2024 Brand Name | Powered By",
    emailLabel: "Email",
    poweredBy: "Dijinx",
  },
};

export default englishTranslations;
