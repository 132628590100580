export const lightColors = {
  primary: "#000000",
  background: "#FFFFFF",
  buttonText: "#78350F",
  demoButtonText: "#2563EB",
  logoGradStart: "#A855F7",
  logoGradEnd: "#F59E0B",
  menuText: "#0F172A",
  ctaBg: "#FCD34D",
  videoRound: "#F59E0B",
  yogaRound: "#A855F7",
  yogaMatRound: "#0B78F5",
  lightBlueRound: "#E0F2FE",
  stat1: "#EAD3FF",
  stat2: "#FFB2304A",
  stat3: "#DBEAFE",
  demoBg: "#E8F5FF",
  featureBorder: "#4C1D95",
  featureText: "#475569",
  pricingText: "#A6A6A6",
  proPrice: "#FFB230",
  priceCircle: "#FFC96F",
  footerText: "#6F6F6F",
  redScreen: "#E11D48",
  yellowScreen: "#FBBF24",
  greenScreen: "#22C55E",
  screenText: "#94A3B8",
  screenBox: "#838c9820",
  purpleShape: "#A855F780",
  screenBg: "#f3f4f6",
  // Add more colors as needed
};

export const darkColors = {
  primary: "#FFFFFF",
  background: "#000000",
  buttonText: "#000000",
  logoGradStart: "#A855F7",
  logoGradEnd: "#F59E0B",
  // Add more colors as needed
};

export const getColors = (isDarkMode) =>
  isDarkMode ? darkColors : lightColors;
