import React from "react";
import { motion } from "framer-motion";
import spacings from "../../theme/spacings";
import { useTheme } from "../../providers/theme-provider";
import { fontSizes } from "../../theme/font-sizes";
import CtaButton from "../buttons/cta-button/cta-button";
import useWindowSize from "../../hooks/use-window-size";
import { useTranslation } from "react-i18next";

const CtaSection = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const paddingTopBottom = isMobile
    ? spacings.mobileTopBottomPagePadding
    : spacings.topBottomPagePadding;
  const paddingLeftRight = isMobile
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  return (
    <div
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
    >
      <motion.div
        className="flex flex-col justify-center items-center gap-6 h-[400px] rounded-[20px]"
        style={{
          backgroundImage: `linear-gradient(to right, ${colors.logoGradEnd}, ${colors.logoGradStart})`,
          padding: spacings.innerPrice,
        }}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <motion.h3
          className="font-bold text-center"
          style={{
            fontSize: fontSizes.heading3,
            color: colors.background,
          }}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          {t("ctaSection.title")}
        </motion.h3>

        <motion.p
          className="max-w-5xl text-center"
          style={{
            color: colors.background,
            fontSize: fontSizes.body,
          }}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          {t("ctaSection.description")}
        </motion.p>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
          viewport={{ once: true, amount: 0.3 }}
        >
          <CtaButton label={t("ctaSection.buttonLabel")} />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default CtaSection;
