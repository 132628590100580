import React from "react";
import { motion } from "framer-motion"; // Importing motion for animations
import { useTheme } from "../../providers/theme-provider";
import spacings from "../../theme/spacings";
import { assetIcons } from "../../constants/assets";
import { fontSizes } from "../../theme/font-sizes";
import useWindowSize from "../../hooks/use-window-size";
import { useTranslation } from "react-i18next";
import useSmoothScroll from "../../hooks/use-smooth-scroll";
import { Link } from "react-router-dom";
import { socialRedirectUrls, urls } from "../../constants/urls";

const FooterLarge = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const handleScroll = useSmoothScroll();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const paddingTopBottom = isMobile
    ? spacings.mobileTopBottomPagePadding
    : spacings.topBottomPagePadding;
  const paddingLeftRight = isMobile
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  return (
    <footer
      style={{
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
    >
      <motion.div
        className="rounded-[20px] overflow-x-hidden"
        style={{
          backgroundColor: colors.demoBg,
          padding: spacings.innerPrice,
        }}
        initial={{ opacity: 0, y: 20 }} // Initial state for footer
        whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
        transition={{ duration: 0.5, ease: "easeInOut" }} // Transition settings
        viewport={{ once: true, amount: 0.3 }}
      >
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
            {/* First Column */}
            <motion.div
              className="flex flex-col gap-4"
              initial={{ opacity: 0, y: 20 }} // Initial state for first column
              whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 }} // Delay for staggered effect
              viewport={{ once: true, amount: 0.3 }}
            >
              <li
                className="list-none cursor-pointer"
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                <Link to={urls.dijinx}>{t("footer.getStarted")}</Link>
              </li>
              <li
                className="list-none cursor-pointer"
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                <Link to={urls.dijinx}>{t("footer.watchDemo")}</Link>
              </li>
              <div className="mt-3 flex gap-6 items-center flex-wrap">
                <p
                  className=""
                  style={{
                    color: colors.footerText,
                    fontSize: fontSizes.footer,
                  }}
                >
                  {t("footer.followUs")}
                </p>
                <div className="flex gap-4">
                  <Link to={socialRedirectUrls.facebook} target="_blank">
                    <motion.img
                      src={assetIcons.facebook}
                      alt=""
                      className="cursor-pointer"
                      whileHover={{ scale: 1.2, duration: 0.8 }}
                    />
                  </Link>
                  <Link to={socialRedirectUrls.instagram} target="_blank">
                    <motion.img
                      src={assetIcons.instagram}
                      alt=""
                      className="cursor-pointer"
                      whileHover={{ scale: 1.2, duration: 0.8 }}
                    />
                  </Link>
                  <Link to={socialRedirectUrls.twitter} target="_blank">
                    <motion.img
                      src={assetIcons.twitter}
                      alt=""
                      className="cursor-pointer"
                      whileHover={{ scale: 1.2, duration: 0.8 }}
                    />
                  </Link>
                  <Link to={socialRedirectUrls.linkedIn} target="_blank">
                    <motion.img
                      src={assetIcons.linkedin}
                      alt=""
                      className="cursor-pointer"
                      whileHover={{ scale: 1.2, duration: 0.8 }}
                    />
                  </Link>
                </div>
              </div>
            </motion.div>

            {/* Second Column */}
            <motion.div
              className="flex flex-col gap-4"
              initial={{ opacity: 0, y: 20 }} // Initial state for second column
              whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }} // Delay for staggered effect
              viewport={{ once: true, amount: 0.3 }}
            >
              <li
                className="list-none cursor-pointer"
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                <Link to={urls.dijinx}>{t("footer.terms")}</Link>
              </li>
              <li
                className="list-none cursor-pointer"
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                <Link to={urls.dijinx}>{t("footer.privacy")}</Link>
              </li>
            </motion.div>

            {/* Third Column */}
            <motion.div
              className="flex flex-col gap-4"
              initial={{ opacity: 0, y: 20 }} // Initial state for third column
              whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }} // Delay for staggered effect
              viewport={{ once: true, amount: 0.3 }}
            >
              <li
                className="list-none cursor-pointer"
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                <Link to={urls.dijinx}>{t("footer.feedback")}</Link>
              </li>
              <li
                className="list-none cursor-pointer"
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                <Link to={urls.dijinx}>{t("footer.helpDesk")}</Link>
              </li>
            </motion.div>

            {/* Fourth Column */}
            <motion.div
              className="flex flex-col gap-4"
              initial={{ opacity: 0, y: 20 }} // Initial state for fourth column
              whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.4 }} // Delay for staggered effect
              viewport={{ once: true, amount: 0.3 }}
            >
              <h3
                className=""
                style={{
                  color: colors.footerText,
                  fontSize: fontSizes.footer,
                }}
              >
                {t("footer.subscribe")}
              </h3>
              <div className="relative">
                <label
                  className="absolute -top-2 left-3 px-1 text-xs z-10 "
                  style={{
                    color: colors.footerText,
                    backgroundColor: colors.demoBg,
                  }}
                >
                  {t("footer.emailLabel")}
                </label>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <img src={assetIcons.email} alt="" />
                  </div>
                  <input
                    type="email"
                    placeholder={t("footer.emailPlaceholder")}
                    className="w-full rounded-md border text-sm  focus:outline-none"
                    style={{
                      background: "transparent",
                      paddingTop: spacings.emailY,
                      paddingBottom: spacings.emailY,
                      paddingLeft: spacings.emailLeft,
                      paddingRight: spacings.emailRight,
                      borderColor: colors.footerText,
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </div>

          {/* Copyright Section */}
          <motion.div
            className="mt-8 border-t pt-8 text-center text-sm"
            style={{
              color: colors.footerText,
              borderTopColor: colors.footerText,
              fontSize: fontSizes.footer,
            }}
            initial={{ opacity: 0, y: 20 }} // Initial state for copyright section
            whileInView={{ opacity: 1, y: 0 }} // Animate to visible when in view
            transition={{ duration: 0.5, ease: "easeInOut", delay: 0.5 }} // Delay for staggered effect
            viewport={{ once: true, amount: 0.3 }}
          >
            {t("footer.copyright")}{" "}
            <Link to={urls.dijinx} target="_blank" className="underline">
              {t("footer.poweredBy")}
            </Link>
          </motion.div>
        </div>
      </motion.div>
    </footer>
  );
};

export default FooterLarge;
