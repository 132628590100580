import React from "react";
import { motion } from "framer-motion";
import { useTheme } from "../../../providers/theme-provider.jsx";
import { fontSizes } from "../../../theme/font-sizes.jsx";
import spacings from "../../../theme/spacings.jsx";
import { useTranslation } from "react-i18next";
import useSmoothScroll from "../../../hooks/use-smooth-scroll.js";

const navVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
};

const itemVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.4, ease: "easeOut" } },
};

const NavbarLarge = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const handleScroll = useSmoothScroll();

  // Inline styles moved to a styles object for readability
  const styles = {
    nav: {
      backgroundColor: colors.background,
      padding: `${spacings.topBottomPagePaddingNav}px ${spacings.leftRightPagePaddingNav}px`,
      gap: `${spacings.navbarSpacing}px`,
    },
    logoText: {
      backgroundImage: `linear-gradient(to right, ${colors.logoGradStart}, ${colors.logoGradEnd})`,
      fontSize: fontSizes.logoText,
    },
    menuText: {
      color: colors.menuText,
    },
  };

  return (
    <motion.nav
      className="flex flex-row items-center h-[84px]"
      style={styles.nav}
      initial="hidden"
      animate="visible"
      variants={navVariants}
    >
      <LogoText style={styles.logoText} text={t("logoText")} />

      <NavigationMenu
        menuItems={[
          { label: t("navItems.demo"), target: "demo" },
          { label: t("navItems.features"), target: "features" },
          { label: t("navItems.pricing"), target: "pricing" },
          { label: t("navItems.faq"), target: "faq" },
        ]}
        onScroll={handleScroll}
        style={styles.menuText}
      />
    </motion.nav>
  );
};

const LogoText = ({ text, style }) => (
  <motion.h3
    style={style}
    className="bg-clip-text text-transparent font-bold cursor-pointer"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.7, delay: 0.3 }}
  >
    {text}
  </motion.h3>
);

const NavigationMenu = ({ menuItems, onScroll, style }) => (
  <motion.ul
    className="flex flex-row min-[1120px]:gap-12 gap-6 items-center font-semibold"
    style={style}
    initial="hidden"
    animate="visible"
    variants={{
      visible: { transition: { staggerChildren: 0.2 } },
    }}
  >
    {menuItems.map((item, index) => (
      <MenuItem
        key={index}
        label={item.label}
        onClick={() => onScroll(item.target)}
      />
    ))}
  </motion.ul>
);

const MenuItem = ({ label, onClick }) => (
  <motion.li
    className="cursor-pointer relative"
    onClick={onClick}
    variants={itemVariants}
    whileHover={{
      scale: 1.1,
    }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    {label}
  </motion.li>
);

export default NavbarLarge;
