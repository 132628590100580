// src/hooks/useSmoothScroll.js
import { useCallback } from "react";

const useSmoothScroll = () => {
    const handleScroll = useCallback((sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, []);

    return handleScroll;
};

export default useSmoothScroll;
