// const fontSizes = {
//   logoText: "22px",
//   heading1: "4vw",
//   heading2: "1.2vw",
//   heading3: "52px",
//   titleText: "20px",
//   buttonText: "16px",
//   priceTitle: "28px",
//   body: "18px",
//   info: "13px",
//   price: "48px",
//   footer: "16px",
//   // Add more as needed
// };

// const smallFontSizes = {
//   heading1: "34px",
//   heading2: "20px",
//   body: "14px",
//   info: "13px",
//   // Add more as needed
// };

const fontSizes = {
  logoText: "clamp(20px, 2vw, 24px)", // Adjusts between 18px and 22px
  heading1: "clamp(34px, 4vw, 66px)", // Adjusts between 34px and 66px
  heading2: "clamp(16px, 1.5vw, 24px)", // Adjusts between 16px and 24px
  heading3: "clamp(30px, 3vw, 52px)", // Adjusts between 30px and 52px
  titleText: "clamp(18px, 1.5vw, 20px)", // Adjusts between 18px and 20px
  buttonText: "clamp(14px, 1vw, 16px)", // Adjusts between 14px and 16px
  priceTitle: "clamp(24px, 2.5vw, 28px)", // Adjusts between 24px and 28px
  body: "clamp(14px, 1.5vw, 18px)", // Adjusts between 14px and 18px
  innerPrice: "clamp(14px, 1.5vw, 18px)", // Adjusts between 14px and 18px
  info: "clamp(12px, 1vw, 13px)", // Adjusts between 12px and 13px
  price: "clamp(32px, 4vw, 48px)", // Adjusts between 32px and 48px
  footer: "clamp(14px, 1.2vw, 16px)", // Adjusts between 14px and 16px
  // Add more as needed
};

export { fontSizes };
