import React from "react";
import { motion } from "framer-motion"; // Import Framer Motion
import { useTheme } from "../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../theme/spacings";
import { assetIcons } from "../../constants/assets";
import StatsCard from "./stats-card";
import useWindowSize from "../../hooks/use-window-size";

const cardVariants = {
  hidden: { opacity: 0, translateY: 20 }, // Start state
  visible: {
    opacity: 1,
    translateY: 0,
    transition: { duration: 0.5, ease: "easeOut" },
  }, // End state
};

const Stats = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const paddingTopBottom = isMobile
    ? spacings.mobileTopBottomPagePadding
    : spacings.topBottomPagePadding;
  const paddingLeftRight = isMobile
    ? spacings.mobileLeftRightPagePadding
    : spacings.leftRightPagePadding;

  const stats = [
    {
      icon: assetIcons.video,
      title: t("stats.stat1.title"),
      description: t("stats.stat1.description"),
      iconBg: colors.stat1,
    },
    {
      icon: assetIcons.users,
      title: t("stats.stat2.title"),
      description: t("stats.stat2.description"),
      iconBg: colors.stat2,
    },
    {
      icon: assetIcons.clock,
      title: t("stats.stat3.title"),
      description: t("stats.stat3.description"),
      iconBg: colors.stat3,
    },
  ];

  return (
    <div
      className="grid md:grid-cols-3 grid-cols-1 place-items-start place-content-center justify-items-center justify-center gap-8 overflow-x-hidden"
      style={{
        backgroundColor: colors.background, // Fixed typo from backgoround to background
        padding: `${paddingTopBottom}px ${paddingLeftRight}px`,
      }}
    >
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          variants={cardVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <StatsCard stat={stat} />
        </motion.div>
      ))}
    </div>
  );
};

export default Stats;
