import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTheme } from "../../../providers/theme-provider";
import { useTranslation } from "react-i18next";
import spacings from "../../../theme/spacings";
import { fontSizes } from "../../../theme/font-sizes";
import { assetIcons } from "../../../constants/assets";
import useSmoothScroll from "../../../hooks/use-smooth-scroll";

const NavbarSmall = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleScroll = useSmoothScroll();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      className="relative"
      style={{
        backgroundColor: colors.backgoround,
        padding: `${spacings.mobileTopBottomPagePaddingNav}px ${spacings.mobileLeftRightPagePaddingNav}px`,
        gap: `${spacings.navbarSpacing}px`,
      }}
    >
      <motion.nav
        className="flex items-center justify-between px-6 py-4 bg-white/80 rounded-xl"
        initial={{ opacity: 0, translateY: -20 }} // Initial state for animation
        animate={{
          opacity: 1,
          translateY: 0,
        }} // Animate based on isOpen state
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <div>
          <h3
            style={{
              backgroundImage: `linear-gradient(to right, ${colors.logoGradStart}, ${colors.logoGradEnd})`,
              fontSize: fontSizes.logoText,
            }}
            className="bg-clip-text text-transparent font-bold cursor-pointer"
          >
            {t("logoTextSmall")}
          </h3>
        </div>

        <button
          onClick={toggleMenu}
          className="text-gray-800 focus:outline-none"
        >
          {isOpen ? (
            <img src={assetIcons.close} alt="close" className="h-6 w-6" />
          ) : (
            <img src={assetIcons.menuBar} alt="menu" className="h-6 w-6" />
          )}
        </button>
      </motion.nav>

      <motion.div
        className={`absolute top-full left-7 right-7 bg-white rounded-xl shadow-md z-50`}
        initial={{ opacity: 0, translateY: -20 }} // Initial state for animation
        animate={{
          opacity: isOpen ? 1 : 0,
          translateY: isOpen ? 0 : -20,
        }} // Animate based on isOpen state
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="relative px-6 py-4">
          {/* <div className="flex items-center justify-between mb-4">
            <h3
              style={{
                backgroundImage: `linear-gradient(to right, ${colors.logoGradStart}, ${colors.logoGradEnd})`,
                fontSize: fontSizes.logoText,
              }}
              className="bg-clip-text text-transparent font-bold cursor-pointer"
            >
              {t("logoTextSmall")}
            </h3>
            <button
              onClick={toggleMenu}
              className="absolute top-4 right-4 text-gray-800 focus:outline-none"
            >
              <img src={assetIcons.close} alt="close" className="w-6 h-6" />
            </button>
          </div> */}

          <nav>
            <motion.ul
              className="space-y-4 font-semibold"
              style={{ color: colors.menuText }}
            >
              {/* Add hover animation to each menu item */}
              {["demo", "features", "pricing", "faq"].map((item) => (
                <MenuItem
                  key={item}
                  label={t(`navItems.${item}`)}
                  onClick={() => {
                    handleScroll(item);
                    setIsOpen(false);
                  }}
                />
              ))}
            </motion.ul>
          </nav>
        </div>
      </motion.div>
    </div>
  );
};

// MenuItem component with hover animation
const MenuItem = ({ label, onClick }) => (
  <motion.li
    className="cursor-pointer"
    onClick={onClick}
    whileHover={{ scale: 1.05, color: "#ff6b6b" }} // Scale and color change on hover
    transition={{ type: "spring", stiffness: 300 }}
  >
    {label}
  </motion.li>
);

export default NavbarSmall;
