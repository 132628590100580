import React from "react";
import HeaderLarge from "../../components/header/header-large";
import Stats from "../../components/stats/stats-section";
import VideoDemo from "../../components/video-demo/video-demo-section";
import FeatureSection from "../../components/features/feature-section";
import PricingSection from "../../components/pricing/pricing-section";
import FAQSection from "../../components/faq/faq-section";
import CtaSection from "../../components/cta-section/cta-section";
import FooterLarge from "../../components/footer/footer-large";
import useWindowSize from "../../hooks/use-window-size";
import HeaderSmall from "../../components/header/header-small";

function HomePage() {
  const { width } = useWindowSize();
  const isTablet = width < 1024;

  return (
    <div>
      {isTablet ? <HeaderSmall /> : <HeaderLarge />}
      <Stats />
      <VideoDemo />
      <FeatureSection />
      <PricingSection />
      <FAQSection />
      <CtaSection />
      <FooterLarge />
    </div>
  );
}

export default HomePage;
