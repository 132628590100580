import React, { useEffect, useState } from "react";
import { fontSizes } from "../../theme/font-sizes";
import { useTheme } from "../../providers/theme-provider";
import {
  motion,
  useMotionValue,
  useTransform,
  animate,
  useInView,
} from "framer-motion";

const StatsCard = ({ stat }) => {
  const { colors } = useTheme();

  // Extract the number from the title
  const finalCount = parseInt(stat.title.match(/\d+/)[0]);
  const remainingText = stat.title.replace(finalCount, "");

  // Initialize motion value for the counter
  const count = useMotionValue(0);
  const roundedCount = useTransform(count, (latest) => Math.floor(latest));

  // State to hold the rendered count
  const [displayCount, setDisplayCount] = useState(0);

  // Ref to detect if the component is in view
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      // Subscribe to the motion value and update the state for display
      const unsubscribe = roundedCount.onChange((latest) =>
        setDisplayCount(latest)
      );

      // Animate from 0 to finalCount
      animate(count, finalCount, { duration: 2, ease: "easeOut" });

      // Clean up the subscription on unmount
      return unsubscribe;
    }
  }, [count, finalCount, roundedCount, isInView]);

  return (
    <div ref={ref} className="flex flex-col gap-4 items-center">
      <div
        className={` w-20 h-20 rounded-full p-4`}
        style={{
          backgroundColor: stat.iconBg,
        }}
      >
        <img src={stat.icon} alt="" />
      </div>
      <div>
        <h3
          className="font-bold"
          style={{
            fontSize: fontSizes.titleText,
            color: colors.menuText,
          }}
        >
          {displayCount}
          {remainingText}
        </h3>
      </div>
      <div>
        <p
          className="text-center max-w-[280px]"
          style={{
            color: colors.menuText,
          }}
        >
          {stat.description}
        </p>
      </div>
    </div>
  );
};

export default StatsCard;
